const modifyDate = (dateHold) => {
	const now = new Date(Date.now())
	let result = String(dateHold.getHours()).padStart(2, '0') + ":" + String(dateHold.getMinutes()).padStart(2, '0')
	const diffTime = Math.abs(dateHold - now);
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
	let msgTime = [
		dateHold.getMonth() + 1,
		dateHold.getDate(),
		dateHold.getFullYear()
	]
	let nowTime = [
		now.getMonth() + 1,
		now.getDate(),
		now.getFullYear()
	]
	if (diffDays <= 1 && msgTime[1] == nowTime[1]){
		result = "Today at " + result
	}
	else if (diffDays == 1 && msgTime[1] != nowTime[1]){
		result = "yesterday " + result
	}
	else if(diffDays > 1 && diffDays <= 6) {
		let msgDay
		switch(diffDays){
			case 0:
				msgDay = "Sunday"
				break;
			case 1:
				msgDay = "Monday"
				break;
			case 2:
				msgDay = "Tuesday"
				break;
			case 3:
				msgDay = "Wednesday"
				break;
			case 4:
				msgDay = "Thursday"
				break;
			case 5:
				msgDay = "Friday"
				break;
			case 6:
				msgDay = "Saturday"
				break;
		}
		result = msgDay + " " +  result
	}
	else if(diffDays > 6){
		result = String(msgTime[0]).padStart(2, '0') + "/" + String(msgTime[1]).padStart(2, '0') + "/" + msgTime[2] + " " + result
	}
	return result
};

export default modifyDate
